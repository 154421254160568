<!-- Main Wrapper -->
<div class="main-wrapper login-body bg-img">
    <div class="login-wrapper">
      <div class="container">
        <div class="loginbox">
          <div class="login-right">
            <div class="login-right-wrap">
              <img class="img-fluid logo-dark mb-2" src="assets/img/logo-small.png" alt="Logo" routerLink="['/']"
              style="height: 10vw; width: 20vw !important;"/>
                <br>
              <h1>{{ 'emailVerification' | translate }}</h1>
              <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="form-group">
                  <label class="form-control-label">{{ 'user.emailAddress' | translate }}<span class="text-danger">*</span></label>
                  <input class="form-control" type="text" formControlName="email" placeholder="{{ 'enterYour' | translate }}{{ 'user.emailAddress' | translate }}"
                    id="email" [ngClass]="{
                      'is-invalid': form.get('email').touched && form.get('email').invalid
                    }" />
                  <div class="invalid-feedback" *ngIf="CustomControler?.status === 'email'">
                    {{ CustomControler.message }}
                  </div>
                  <div *ngIf="(submitted || form.get('email').touched) && form.get('email').invalid" class="custom-invalid-feedback">
                    <div *ngIf="form.get('email').errors && form.get('email').errors['required']">
                      {{ 'emailRequired' | translate }}
                    </div>
                    <div *ngIf="form.get('email').errors && form.get('email').errors['email']">
                      {{ 'enterValidEmail' | translate }}
                    </div>
                  </div>
                </div>
                <button *ngIf="!waitingResponse && !linkSent" class="btn btn-lg btn-block btn-primary w-100" type="submit">
                  {{ 'send' | translate }}
                </button>
                <div *ngIf="linkSent">
                  <div class="custom-alert bg-success text-light">
                    {{ 'errorResponse.emailVerifyLinkSent' | translate }}
                  </div>
                </div>
                <div *ngIf="waitingResponse">
                  <app-progress-indeterminate></app-progress-indeterminate>
                </div>
                <div class="login-or">
                  <span class="or-line"></span>
                  <span class="span-or">{{ 'or' | translate }}</span>
                </div>
                <!-- Social Login -->
                <div class="social-login mb-3">
                  <span>{{ 'connectWith' | translate }}</span>
                  <a href="javascript:void(0);" class="facebook"><i class="fab fa-facebook-f"></i></a><a
                    href="javascript:void(0);" class="google"><i class="fab fa-google"></i></a>
                </div>
                <!-- /Social Login -->
              <div class="text-center dont-have">
                <a [routerLink]="'/login'" style="color: #1749a5;">{{ 'connexion' | translate}} </a>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <app-language-template></app-language-template>
  <!-- /Main Wrapper -->