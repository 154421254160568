import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndeterminateComponent } from './progress-indeterminate.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    ProgressIndeterminateComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule
  ],
  exports: [ProgressIndeterminateComponent]
})
export class ProgressIndeterminateModule { }
