<div class="row" >
   <div class="col-12 item-center" style="text-align: center">
    <img *ngIf="type==false" src="../../../../assets/img/loader.gif" class="loader_img"><br>
    <img  *ngIf="type==true" src="../../../../assets/img/loader_line.gif" class="loader_img" style="width: 200px;"><br>
        <!-- <div class="card-body">
            <p class="text-center">
                <strong>{{message}}</strong>
            </p>            
        </div> -->
    </div>
</div>